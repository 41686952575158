import React from 'react'

export const Twitch = () => (
  <div className="container">
    <h2>Twitch Things</h2>
    <ul>
      <li><a href="https://bakkesplugins.com/plugins/view/215">Rocket League Stream API Plugin for BakkesMod</a> / <a href="https://github.com/daftpenguin/StreamAPIPlugin">GitHub</a></li>
      <ul>
        <li>API for creating chat commands that automatically update so viewers can retrieve your settings, equipped items, ranks, and more as you change them in game.</li>
      </ul>
      <li><a href="https://github.com/daftpenguin/StreamLabsRocketLeagueRandomTeamCreator">
        Streamlabs Chatbot script that generates teams from viewers who !register
      </a></li>
      <li>Rank APIs for <a href="/rocket-league">Rocket League</a>, <a href="/valorant">Valorant</a>, and <a href="/apex-legends">Apex Legends</a></li>
      <li>DaftPenguinBot: a custom built Twitch and Discord bot, mostly built for automating moderator tasks for Twitch channels that I moderate, and some light stat tracking.</li>
      <li><a href="/youtube">YouTube Subcount API</a></li>
    </ul>
  </div>
)