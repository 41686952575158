import React from 'react'

export const About = () => (
  <div className="container">
    <h2>Who is DaftPenguin?</h2>
    <p>Fuck if I know. Lemme know if you find out...</p>

    <h4>Specs:</h4>
    <p>I have a BS and MS in Computer Science and I work full-time as a software engineer for a large internet company based in the SF bay area.</p>
    
    <p>Much of my work is centered around backend distributed data services, and researching and testing new technologies (primarily in the area of networking) for the purposes of improving the efficiency of the platforms/frameworks being leveraged by the thousands of services within my company. I have very little experience with web and game development, hence why this site is fairly basic and why my BakkesMod plugins have many <span style={{ textDecoration: "line-through" }}>bugs</span> features.</p>
    
    <p>I am often asked about programming languages I use. There's honestly quite a few languages I've used professionally, but I would never claim a high level of proficiency for any of them. The languages I've used professionally include: Go, C/C++, Python, a bit of JavaScript, Java, and bash if you count that. Don't ask me my favorite as it always depends on the job.</p>

    <h3>Where you can find me:</h3>
    <ul>
      <li>DaftPenguinRL at gmail dot com</li>
      <li><a href="https://twitter.com/PenguinDaft">Twitter: @PenguinDaft</a></li>
      <li><a href="https://discordapp.com/users/387477633220345857">Discord: DaftPenguin#5103</a></li>
      <li><a href="https://twitch.com/daftpenguin">Twitch: DaftPenguin</a> (I don't stream, but whispers are open and you can find me in many RL streams)</li>
      <li><a href="https://steamcommunity.com/id/daftpenguinrl">Steam: DaftPenguinRL</a></li>
    </ul>

    <p>I enjoy playing around with interesting ideas and I also like to help streamers / content creators with creating new content or helping them implement interesting features to their streams for improving viewer interactions. If you have anything you think I may be helpful with, please don't hesitate to contact me. I don't usually expect compensation unless it's particularly complicated to implement and I'm not especially interested in the idea, you expect exclusitivity of the product being developed, or you expect to sell it or receive significant compensation for it.</p>
  </div>
)