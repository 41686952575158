const dotenv = require("dotenv");

dotenv.config();

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const streamAPITokenServer = process.env.REACT_APP_STREAMAPITOKEN_SERVER_URL;

if (!domain) {
  throw new Error(
    ".env is missing the definition of an REACT_APP_AUTH0_DOMAIN variable",
  );
}

if (!clientId) {
  throw new Error(
    ".env is missing the definition of an REACT_APP_AUTH0_CLIENT_ID variable",
  );
}

if (!audience) {
  throw new Error(
    ".env is missing the definition of an AUTH0_AUDIENCE environmental variable",
  );
}

if (!streamAPITokenServer) {
  throw new Error(
    ".env is missing the definition of an REACT_APP_STREAMAPITOKEN_SERVER_URL environmental variable",
  );
}

module.exports = {
  domain,
  clientId,
  audience,
  streamAPITokenServer,
};
