import React from 'react'

export const ApexLegends = () => (
  <div className="container">
    <h2>Apex Legends Things</h2>
    <ul>
    <li>Rank API for Twitch: <a href="https://daftpenguin.com/api/apex-legends/rank?name=ebecheese">https://daftpenguin.com/api/apex-legends/rank?name=ebecheese</a></li>
      <div>Replace name with your info. This uses an API from <a href="https://apex.tracker.gg/">https://apex.tracker.gg/</a> and has a request limit of 30 requests per minute, so please don't abuse it and preferably only use it as a command for looking up the streamer's rank and not a generalized version for anyone in chat to lookup their own ranks. Ranks are cached for 60 seconds, so frequent calls for the same person's rank is fine.</div>
    </ul>
  </div>
)