import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import './styles/LoginCard.css';
import { DoorOpenFill } from 'react-bootstrap-icons';

const LoginCard = () => {
  const { logout, loginWithRedirect, user, isAuthenticated, isLoading } = useAuth0();

  const doLogin = () => {
    loginWithRedirect();
  }

  if (isLoading) {
    return (
      <div className="card">
        <div className="loading">Logging in <span style={{ fontSize: "small" }}>(sometimes buggy)</span></div>
      </div>
    );
  } else if (isAuthenticated) {
    return (
      <div className="card">
        <div className="info">
          <img className="profile" src={user.picture} alt={user.name} />
          <div className="textInfo">
            <span className="highlight">{user.name}</span><br/>
            <span>{user.email}</span>
          </div>
        </div>
        <DoorOpenFill alt="Logout" size={24} className="logout" onClick={() => logout({ returnTo: window.location })} />
      </div>
    );
  }

  return (
    <div className="card">
      <div className="login"> {/*onClick={doLogin}>*/}
        <DoorOpenFill alt="Login" size={28} /> Login
        {/* <div style={{display: "none"}} onClick={doLogin}>Test</div> */}
      </div>
    </div>
  );
};

export default LoginCard;
