module.exports = {
  platformFromUser: platformFromUser,
};

function platformFromUser(user) {
  if (user.sub) {
    if (user.sub.indexOf("google") > -1) {
      return "YouTube";
    } else if (user.sub.indexOf("twitch") > -1) {
      return "Twitch";
    }
  }

  return "Twitch";
}
