const crypto = require('crypto');

module.exports = {
  generateSecret: generateSecret,
  validateSecret: validateSecret,
};

function generateSecret(token)
{
  const hash = crypto.createHash('sha256').update(token).digest('hex'); // 64 characters
  const offset = parseInt(token[30], 16); // Offset is at most 16, 16 + 16 < 64
  return hash.substr(offset, 16);
}

function validateSecret(token, secret)
{
  const expected = generateSecret(token);
  return (expected === secret);
}