import React from 'react'

export const Home = () => (
  <div className="container">
    <h2>Why does this site exist?</h2>
    <p>I needed a host and webserver to support features related to some of the projects I have created and/or projects I am currently working on.</p>
    <p>I figured I'd populate this site with a few things in case someone happened upon it, and share some of the things I have built.</p>
    <p>There's a few things here, with more to come.</p>
    <p>Also check out my <a href="https://github.com/daftpenguin">GitHub</a> for potential spoilers.</p>
  </div>
)