import React from "react";
//import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { domain, clientId, audience } from "./config/react.env";

const Auth0ProviderWithHistory = ({ children }) => {
  /*
  const history = useHistory();

  const onRedirectCallback = (appState) => {
    console.log("history push: " + appState?.returnTo || window.location.pathname);
    history.push(appState?.returnTo || window.location.pathname);
  };*/

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin + "/rocket-league/stream-api"}
      //onRedirectCallback={onRedirectCallback}
      audience={audience}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;