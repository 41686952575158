import React, { Component } from 'react';
import axios from 'axios';
import './styles/SongList.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Spinner from 'react-bootstrap/Spinner'


class SongList extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      currentSong: null,
      songQueue: [],
      isLoading: true,
      hasLoaded: true,
    }
    this.getSongs(false);
  }

  getSongs = (forceUpdate) => {   
    axios.get(`/api/trihouse/songlist?forceUpdate=${forceUpdate}`)
      .then(res => {
        this.setState({ isLoading: false });
        console.log(res.data);
        if (res.status === 200) {
          this.setState({ currentSong: res.data.current, songQueue: res.data.list });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  handleForceUpdate = event => {
    event.preventDefault();
    this.setState( { isLoading: true, hasLoaded: true });
    this.getSongs(true);
  }

  render() {
    var currentSongDiv = "";
    if (!this.state.isLoading && this.state.currentSong) {
        currentSongDiv = <div>
            <p><b>Current Song:</b> <a href={this.state.currentSong.uri}>{this.state.currentSong.title}</a> ({this.state.currentSong.duration}) requested by {this.state.currentSong.by}</p>
        </div>
    }

    var songListTable = "";
    if (!this.state.isLoading && Object.entries(this.state.songQueue).length > 0) {
      songListTable = <table className="songList">
        <thead>
          <tr>
            <th>Song</th>
            <th>Requester</th>
            <th>Duration</th>
          </tr>
        </thead>
        <tbody>
          {this.state.songQueue.map(function(song) {
            return (
              <tr key={song.id}>
                <td><a href={song.uri}>{song.title}</a></td>
                <td>{song.by}</td>
                <td>{song.duration}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    } else if (!this.state.isLoading && this.state.hasLoaded && !this.state.currentSong) {
      songListTable = "Failed to retrieve songs list";
    } else {
      songListTable = "No more songs in the queue";
    }

    var spinner = "";
    if (this.state.isLoading) {
      spinner = <div style={{ display: "inline" }}>
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
    }

    return (
      <div>
        {spinner}
        {currentSongDiv}
        <div className="songList">
          {songListTable}
        </div>
        <div>
          <button onClick={this.handleForceUpdate}>Force update</button>
        </div>
      </div>
    )
  }
}

export default SongList;