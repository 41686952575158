import React, { useState, useRef, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './styles/BotCommands.css';
import 'react-tabs/style/react-tabs.css';
import { Clipboard } from 'react-bootstrap-icons';
import LoginCard from "./LoginCard"
import { useAuth0 } from "@auth0/auth0-react";
import { platformFromUser } from "../helpers/platformFromUser";
import FlashMessage from 'react-flash-message';
import saveAs from 'file-saver';
import { generateSecret } from '../api/helpers/streamapi';
import { streamAPITokenServer } from "../config/react.env";

// TODO: WizeBot
// TODO: More command customizations
// TODO: Share json endpoint

const botNames = {
  local: [ "Streamlabs Chatbot", "Mix It Up", "Other" ],
  external: [ "Nightbot", "Streamlabs Chatbot", "Streamlabs Cloudbot", "StreamElements", "Other" ]
}

const baseURLs = {
  external: (ip, port, userName, platform) => (`https://www.daftpenguin.com/api/rocket-league/stream-api/data/${platform}/${userName}?`),
  local: (ip, port) => (`http://${ip}:${port}/cmd?`)
}

const baseCommands = [
  { name: "loadout", optionalArgs: true, argInfo: "To restrict loadout info, set args with one of: body, decal, wheels, boost, antenna, topper, engine, trail, ge" },
  { name: "sens" },
  { name: "camera" },
  { name: "settings", internalName: "video" },
  { name: "bindings", args: "ds4", argInfo: "Replace ds4 with xbox or kbm for their bindings" },
  { name: "training" },
  { name: "rank", optionalArgs: true, argInfo: "To restrict ranks shown, replace value after args= with comma separated list of playlist names: 1v1,2v2,3v3,hoops,dropshot,rumble,snowday,casual." },
  { name: "workshop" },
]

const ExternalLoginMessage = () => (
  <div>
    <span style={{ color: "var(--red)" }}>Login to customize the external bot commands for your channel</span>
  </div>
)

const BotCommands = (props) => {
  const userPortInput = useRef(null);
  const userIPInput = useRef(null);

  const onCreateMIUZip = (commands) => {
    const zip = require('jszip')();
    var baseURL = `http://${userIPInput.current.value}:${userPortInput.current.value}/cmd`;
    for (const cmd of commands) {
      var args = cmd.args;
      if (cmd.optionalArgs) {
        args = "$allargs"
      }
      zip.file(cmd.name + ".miucommand", `{"$type":"MixItUp.Base.Model.Commands.ChatCommandModel, MixItUp.Base","IncludeExclamation":true,"Wildcards":false,"ID":"86c306f6-c09e-4da1-8f69-dc720ddd18a2","Name":"${cmd.name}","Type":1,"IsEnabled":true,"Unlocked":false,"IsEmbedded":false,"GroupName":null,"Triggers":["${cmd.name}"],"Requirements":{"$type":"MixItUp.Base.Model.Requirements.RequirementsSetModel, MixItUp.Base","Requirements":[{"$type":"MixItUp.Base.Model.Requirements.RoleRequirementModel, MixItUp.Base","Role":10,"SubscriberTier":1,"PatreonBenefitID":""},{"$type":"MixItUp.Base.Model.Requirements.CooldownRequirementModel, MixItUp.Base","Type":0,"IndividualAmount":0,"GroupName":null},{"$type":"MixItUp.Base.Model.Requirements.ThresholdRequirementModel, MixItUp.Base","Amount":0,"TimeSpan":0,"RunForEachUser":false},{"$type":"MixItUp.Base.Model.Requirements.SettingsRequirementModel, MixItUp.Base","DeleteChatMessageWhenRun":false,"DontDeleteChatMessageWhenRun":false,"ShowOnChatContextMenu":false}]},"Actions":[{"$type":"MixItUp.Base.Model.Actions.WebRequestActionModel, MixItUp.Base","Url":"${baseURL}?cmd=${cmd.internalName ? cmd.internalName : cmd.name}${args ? "&args=" + args : ""}","ResponseType":0,"JSONToSpecialIdentifiers":null,"ID":"775b0b4e-5e9a-401a-bd29-723ccda79e87","Name":"Web Request","Type":11,"Enabled":true},{"$type":"MixItUp.Base.Model.Actions.ChatActionModel, MixItUp.Base","ChatText":"$webrequestresult","SendAsStreamer":false,"IsWhisper":false,"WhisperUserName":null,"ID":"9d707855-2839-4ae5-8d88-87596abd0751","Name":"Chat Message","Type":1,"Enabled":true}]}`);
    }
    zip.generateAsync({type: "blob"}).then(content => {
      saveAs(content, "miu-commands.zip");
    });
  }
  
  const botConfigs = {
    "Nightbot": {
      fetch: (commandURL) => (`$(urlfetch ${commandURL})`),
      optionalArgs: "$(query)"
    },
    "Streamlabs Chatbot": {
      fetch: (commandURL) => (`$readapi(${commandURL})`),
      optionalArgs: "$dummyormsg",
    },
    "Streamlabs Cloudbot": {
      fetch: (commandURL) => (`{readapi.${commandURL}}`),
      optionalArgsInfo: <p>Cloudbot doesn't appear to have a variable that allows a viewer to pass in optional args ({"{1}"} requires an argument, {"{1:3}"} requires 3...). Therefore, I have skipped adding the optional arguments to these commands.</p>,
    },
    "StreamElements": {
      fetch: (commandURL) => (`$(urlfetch ${commandURL})`),
      // eslint-disable-next-line no-template-curly-in-string
      //optionalArgs: "${1:}"
      optionalArgsInfo: <p>I haven't figured out StreamElements support for queries yet.</p>,
    },
    "Mix It Up": {
      // TODO: Make different steps copyable
      fetch: (commandURL) => (<span>Plain text web request: <span className="requestURL">{commandURL}</span><br/>Chat message: <span style={{fontFamily: "monospace"}}>$webrequestresult</span></span>),
      optionalArgsInfo: <p>Potentially save some time by <a href="/#" onClick={() => onCreateMIUZip(baseCommands)}>downloading a zip</a> of all exported MIU commands, extract the zip, then import each one (custom action commands not supported at this time). Unfortunately, you still need to name the commands and set the triggers. This zip file is generated on the fly, based on the Port and IP defined above. So if you're not using the defaults, set those values before downloading the zip.</p>,
      commandNotCopyable: true,
    }
  }

  const startExternal = props.botType && props.botType === "external";
  const [botType, setBotType] = useState(startExternal ? "external" : "local");
  const [botName, setBotName] = useState(startExternal ? botNames.external[0] : botNames.local[0])
  const [localBotIndex, setLocalBotIndex] = useState(0);
  const [externalBotIndex, setExternalBotIndex] = useState(0);

  const [copiedMessage, setCopiedMessage] = useState({ show: false });
  const [customIP, setCustomIP] = useState("127.0.0.1");
  const [customPort, setCustomPort] = useState(10111);
  const [customFetch, setCustomFetch] = useState("$(urlfetch <url>)");
  const [customOptionalArgs, setCustomOptionalArgs] = useState("");

  const [actionCommandsConfig, setActionCommandsConfig] = useState(null);
  const [actionCommandsConfigError, setActionCommandsConfigError] = useState("");

  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const userName = (isAuthenticated && !isLoading ? user.name.toLowerCase() : "<username>");
  const platform = (isAuthenticated && !isLoading ? platformFromUser(user).toLowerCase() : "<platform>");

  const handleCopyResponse = (event, response) => {
    event.preventDefault();
    navigator.clipboard.writeText(response);
    setCopiedMessage({ show: true, top: event.pageY, left: event.pageX });
    setTimeout(() => setCopiedMessage({ show: false }), 2000);
  }

  // TODO: We should really put this into a separate component to use in both BotCommands.js and StreamAPIToken.js (also rename StreamAPIToken.js?)
  const [tokenRetrievalError, setTokenRetrievalError] = useState("");
  const [tokenRetrievalAttempted, setTokenRetrievalAttempted] = useState(false);
  const [isLoadingToken, setIsLoadingToken] = useState(false);
  const [actionSecret, setActionSecret] = useState("unknown");
  const getToken = async () => {
    setTokenRetrievalAttempted(true);
    setIsLoadingToken(true);
    setActionSecret("unknown");
    setTokenRetrievalError(null);

    try {
      const token = await getAccessTokenSilently();

      fetch(
        `${streamAPITokenServer}/api/rocket-league/stream-api/get-token`,
        {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(response => {
          console.log(response.status);
          if (response.status !== 200) {
            console.error(`Failed to retrieve token from server. Status code: ${response.status}. User: ${JSON.stringify(user)}`)
            setTokenRetrievalError("Failed to retrieve token from server.");
            return null;
          } else {
            return response.json();
          }
        })
        .catch((e) => {
          console.log(e);
          console.error("Token server appears to be down.");
          setTokenRetrievalError("Failed to retrieve token from server. It appears the token server is down. DaftPenguin has been notified and will fix this eventually. Sorry for the inconvenience.");
        })
        .then(data => {
          console.log(data);
          if (data && data.token) {
            const secret = generateSecret(data.token);
            console.log(`Secret: ${secret}`);
            setActionSecret(secret);
          }
    
          setIsLoadingToken(false);
        })
        .catch((e) => {
          console.log(e);
          console.error("Token server appears to be down.");
          setTokenRetrievalError("Failed to retrieve token from server. It appears the token server is down. DaftPenguin has been notified and will fix this eventually. Sorry for the inconvenience.");
        });
    } catch (error) {
      console.error(`Failed to retrieve token from server. Error message: ${error.message}. User: ${JSON.stringify(user)}`)
      setTokenRetrievalError("Failed to retrieve token from server.");
      setIsLoadingToken(false);
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      if (!tokenRetrievalAttempted) {
        if (!platform) {
          fetch(`/api/error?err=Cannot parse platform from ${user.sub}`);
          setTokenRetrievalError("Failed to parse platform. Cannot create/retrieve token without a valid platform. This error has been logged and reported to the system administrator.");
        } else {
          getToken();
        }
      }
    }
  });

  const generateOther = (botType, baseURL) => {
    var urlIdx = customFetch.indexOf("<url>");
    var botCommands;
    if (urlIdx >= 0) {
      botCommands = generateCommandsWithBotConfig(
        "Other",
        botType,
        {
          fetch: (url) => (customFetch.substring(0, urlIdx) + url + customFetch.substring(urlIdx + 5)),
          optionalArgs: customOptionalArgs && customOptionalArgs !== "" ? customOptionalArgs : null,
        },
        baseURL
      );
    }
    return (
      <div>
        {/*eslint-disable-next-line no-template-curly-in-string*/}
        <p>This form will help you to generate commands for any bots. To use this, find your bot's documentation for custom commands, and search for the command syntax for fetching a response from a URL or API. For instance, StreamElement's method for fetching a URL is: <span style={{ fontFamily: "monospace", color: "var(--red)" }}>$&#123;customapi.link-to-api.com&#125;</span>. Copy that syntax in the textbox below, but replace the notation the bot's documentation uses for the url value with &lt;url&gt;. For example, StreamElement's syntax would be: <span style={{ fontFamily: "monospace", color: "var(--red)" }}>$&#123;customapi.&lt;url&gt;&#125;</span></p>
        <p>To support optional arguments such as <span style={{ fontFamily: "monospace", color: "var(--red)" }}>!loadout goal explosion</span>, check if your bot has any command variables that gives you an <b>OPTIONAL</b> query or all arguments following the command. That is, if your viewer types <span style={{ fontFamily: "monospace", color: "var(--red)" }}>!loadout goal explosion</span> or <span style={{ fontFamily: "monospace", color: "var(--red)" }}>!loadout wheels</span>, there is a variable that will give you <span style={{ fontFamily: "monospace", color: "var(--red)" }}>goal explosion</span> for the former and <span style={{ fontFamily: "monospace", color: "var(--red)" }}>wheels</span> for the latter. A command configured with that variable should also still be called with no query like: <span style={{ fontFamily: "monospace", color: "var(--red)" }}>!loadout</span>. Put the entire variable name into the Optional Query input below.</p>

        <label style={{ paddingRight: "10px" }}>Command Syntax: </label><input type="textbox" value={customFetch} onChange={(event) => setCustomFetch(event.target.value)}></input>
        {urlIdx < 0 && <span style={{ color: "var(--red)" }}>Must have &lt;url&gt;&#125; in syntax</span>}
        <br/>
        
        <label style={{ paddingRight: "10px" }}>Optional Query: </label><input type="textbox" value={customOptionalArgs} onChange={(event) => setCustomOptionalArgs(event.target.value)}></input>
        <br/>
        
        <p>The commands shown below should automatically update as you make your changes.</p>
        {urlIdx >= 0 && botCommands}
      </div>
    );
  }

  const generateCommandsWithBotConfig = (botName, botType, botConfig, baseURL) => {
    return (
      <div>
        {botConfig.optionalArgsInfo}
        <table>
          <thead>
            <tr>
              <th>Command</th>
              <th>Response</th>
              <th>Args Info</th>
            </tr>
          </thead>
          <tbody>
            {baseCommands.map(command => {
              var response = generateCommand(botConfig, baseURL, command);
              return (
                <tr key={botType + botName + command.name}>
                  <td>!{command.name}</td>
                  {!botConfig.commandNotCopyable && <td className="response copyable" onClick={(event) => {handleCopyResponse(event, response)}}>{response}<Clipboard className="clipboard" /></td>}
                  {botConfig.commandNotCopyable && <td className="response">{response}</td>}
                  <td>{command.argInfo}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  const generateCommand = (botConfig, baseURL, command) => {
    var cmdName = command.internalName ? command.internalName : command.name;
    var commandURL = `${baseURL}cmd=${cmdName}`;
    if (command.args && botConfig.optionalArgs) {
      commandURL += `&args=${command.args}`;
    } else if (command.optionalArgs && botConfig.optionalArgs) {
      commandURL += `&args=${botConfig.optionalArgs}`;
    }
    return botConfig.fetch(commandURL);
  }

  const generateActionCommand = (botConfig, botType, baseURL, command) => {
    var commandURL = `${baseURL}cmd=action&cmd_name=${command.name}`;
    Object.keys(command.params).forEach(p => commandURL += `&${p}=${command.params[p]}`);
    if (botType === "external") {
      commandURL += "&secret=" + actionSecret;
    }
    var fetch = botConfig.fetch(commandURL);
    return fetch;
  }

  const generateActionCommandsForOther = (botType, baseURL) => {
    var urlIdx = customFetch.indexOf("<url>");
    var botCommands;
    if (urlIdx >= 0) {
      botCommands = generateActionCommandsWithBotConfig(
        "Other",
        botType,
        {
          fetch: (url) => (customFetch.substring(0, urlIdx) + url + customFetch.substring(urlIdx + 5)),
          optionalArgs: customOptionalArgs && customOptionalArgs !== "" ? customOptionalArgs : null,
        },
        baseURL
      );
    }
    return botCommands;
  }

  const generateActionCommandsWithBotConfig = (botName, botType, botConfig, baseURL) => (
    <div>
      <table>
        <thead>
          <tr>
            <th>Command</th>
            <th>Response</th>
            <th>Parameters (you must change these values)</th>
          </tr>
        </thead>
        <tbody>
          {actionCommandsConfig.map((command, cmdIdx) => {
            const response = generateActionCommand(botConfig, botType, baseURL, command);
            return (
              <tr key={botType + botName + command.name} data-cmd-idx={cmdIdx}>
                <td>!{command.name}</td>
                {!botConfig.commandNotCopyable && <td className="response copyable" onClick={(event) => {handleCopyResponse(event, response)}}><Clipboard className="clipboard" /><span>{response}</span></td>}
                {botConfig.commandNotCopyable && <td className="response">{response}</td>}
                {Object.keys(command.params).map(param => (
                  <td key={botType + botName + command.name + "param" + param} data-param-name={param}>
                    <input type="textbox" placeholder={"${" + param + "}"} value={command.params[param]} onChange={onActionCommandParamChange} />
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  )

  const generateCommands = (botType, botName, isActionCommands) => {
    var ip = botType === "external" ? "www.daftpenguin.com" : customIP;
    var port = botType === "external" ? null : customPort;
    var baseURL = baseURLs[botType](ip, port, userName, platform);
    if (botName === "Other") {
      if (isActionCommands) {
        return generateActionCommandsForOther(botType, baseURL);
      }
      return generateOther(botType, baseURL);
    }

    var botConfig = botConfigs[botName];

    if (isActionCommands) {
      return generateActionCommandsWithBotConfig(botName, botType, botConfig, baseURL);
    }
    return generateCommandsWithBotConfig(botName, botType, botConfig, baseURL);
  }

  const paramsRegex = /\$\{([A-z0-9_]+)\}/g;
  const onActionCommandsConfig = (e) => {
    setActionCommandsConfigError("");
    const configData = e.target.value;
    try {
      const config = JSON.parse(configData);
      if (!config.commands) {
        setActionCommandsConfigError("Invalid commands configuration. Missing commands field.");
        return;
      }

      var commandNames = Object.keys(config.commands);
      if (commandNames.length === 0) {
        setActionCommandsConfigError("No commands in configuration.");
        return;
      }

      // actionCommandsConfig: [ { name: "", params: [ "p1", "p2", ... ] }, ... ]
      var parsedConfig = [];
      for (const commandName of commandNames) {
        var params = {};
        const matches = [...config.commands[commandName].command.matchAll(paramsRegex)].map(m => m[1]);
        for (const p of Array.from(new Set(matches))) {
          params[p] = "${" + p + "}";
        }
        parsedConfig.push({ name: commandName, params: params });
      }
      setActionCommandsConfig(parsedConfig);
    } catch (e) {
      console.log(e);
      setActionCommandsConfigError("Improper JSON format. Perhaps you accidentally entered additional characters? Try deleting everything and pasting it in again.");
    }
  }

  const onActionCommandParamChange = (e) => {
    const param = e.target.parentNode.getAttribute("data-param-name");
    var value = e.target.value;
    const cmdIdx = e.target.parentNode.parentNode.getAttribute("data-cmd-idx");
    var config = [...actionCommandsConfig];
    config[cmdIdx].params[param] = value;
    setActionCommandsConfig(config);
  }

  const onBotTypeChange = (index, lastIndex, event) => {
    if (index === lastIndex) return true;
    const isLocal = index === 1;
    setBotType(isLocal ? "local" : "external");
    setBotName(isLocal ? botNames.local[localBotIndex] : botNames.external[externalBotIndex]);
    return true;
  }

  const onLocalBotChange = (index, lastIndex, event) => {
    if (index === lastIndex) return true;
    setBotName(botNames.local[index]);
    setLocalBotIndex(index);
    return true;
  }

  const onExternalBotChange = (index, lastIndex, event) => {
    if (index === lastIndex) return true;
    setBotName(botNames.external[index]);
    setExternalBotIndex(index);
    return true;
  }

  /* Generating tab data before render */

  var externalTabs = botNames.external.map(bot => {
    return (
      <Tab key={"external-tab-" + bot}>{bot}</Tab>
    )
  });
  var externalTabPanels = botNames.external.map(bot => {
    const commands = generateCommands("external", bot);
    return (
      <TabPanel key={"external-tabPanel-" + bot}>
        {!isAuthenticated && !isLoading && <ExternalLoginMessage />}
        {commands}
      </TabPanel>
    )
  });

  var localTabs = botNames.local.map(bot => {
    return (
      <Tab key={"local-tab-" + bot}>{bot}</Tab>
    )
  });
  var localTabPanels = botNames.local.map(bot => {
    const commands = generateCommands("local", bot);
    return (
      <TabPanel key={"local-tabPanel-" + bot}>
        {commands}
      </TabPanel>
    )
  });

  return (
    <div style={{ paddingBottom: "50px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <LoginCard />
      </div>
      <p>I tried to deliver everything a streamer might need to get this plugin working, but I am not familiar with every single bot's command variables and didn't thoroughly look through all of their documentation. There might be some better commands for some of these bots. Please <a href="/about">contact me</a> if you or your mods have any suggestions for improvement.</p>
      <p>One suggestion is to make a basic command that lists these commands (and other RL related commands) that you can point viewers to.</p>
      
      <Tabs forceRenderTabPanel defaultIndex={botType === "external" ? 0 : 1} onSelect={onBotTypeChange}>
        <TabList>
          <Tab>External</Tab>
          <Tab>Local</Tab>
        </TabList>
        <TabPanel>
          <Tabs forceRenderTabPanel onSelect={onExternalBotChange}>
            <TabList>
              {externalTabs}
            </TabList>
            <h3>Data Commands</h3>
            <p>These are the basic commands for users to retrieve data about your game.</p>
            {externalTabPanels}
          </Tabs>
        </TabPanel>
        <TabPanel>
          <div style={{ paddingTop: "10px", paddingBottom: "20px" }}>
            <label style={{ paddingRight: "10px" }}>Port: </label><input type="textbox" ref={userPortInput} value={customPort} onChange={(event) => {setCustomPort(event.target.value.replace(/\D/g,''))}}></input><br />
            <label style={{ paddingRight: "10px" }}>IP: </label><input type="textbox" ref={userIPInput} value={customIP} onChange={(event) => {setCustomIP(event.target.value)}}></input><br />
            127.0.0.1 is a special IP address that loops back to the same device. Only change the IP if you're running your bot on a separate PC than the one that is running Rocket League, and both PCs are on the same LAN. Set this to the local IP address of the PC that is running Rocket League.
          </div>
        <Tabs forceRenderTabPanel onSelect={onLocalBotChange}>
          <TabList>
            {localTabs}
          </TabList>
          <h3>Data Commands</h3>
          <p>These are the basic commands for users to retrieve data about your game.</p>
          {localTabPanels}
          </Tabs>
        </TabPanel>
      </Tabs>

      <h3 style={{ paddingTop: "20px" }}>Action Commands</h3>
      <p>These are commands that users can run to trigger actions within your game. You have full control over what commands are enabled, the actions these commands take, when they can be triggered, and their internal command names. These must be configured in your plugin settings first, and then exposed by adding commands in your bot.</p>
      <p>To help with configuring the commands for your bot, you can copy your commands configuration from within the plugin's settings in the "Action Commands" tab, and then paste that configuration here in the textbox below.</p>
      {botType === "external" && <p style={{ color: "var(--red)" }}>Warning! External bot support requires this API to be publicly accessible. This means that users could bypass any restrictions you have set in your chat bot by simply figuring out the API calls to make directly to this site. Therefore, an additional "secret" parameter is passed in to validate the request. IF YOU ADD OR EDIT THESE COMMANDS WITHIN YOUR TWITCH CHAT, YOU WILL EXPOSE THIS SECRET. This secret is generated based on the token you created when setting up the plugin for external bot support. Leaking the secret will NOT compromise your token, but if you notice someone abusing the API, you can change this secret by logging in and generating a new token. This will require you to update all your commands with the new secret, and you will need to paste the token back into the plugin's settings.</p>}
      <p>Note that this command generation page is fairly basic and hasn't been tested with more complicated things such as random number generation or picking a random value from a set of values.</p>

      <textarea style={{ width: "100%", height: "150px" }} onChange={onActionCommandsConfig}></textarea>
      {actionCommandsConfigError !== "" && <span style={{ color: "var(--red)" }}>{actionCommandsConfigError}</span>}
      {actionCommandsConfig && generateCommands(botType, botName, true)}

      {botType === "external" && (!isAuthenticated || actionSecret === "unknown") && <p style={{ color: "var(--red)" }}>You're either not logged in, or are currently awaiting token retrieval, and therefore any action commands generated below will be missing the secret needed to make API calls.</p>}
      {botType === "external" && tokenRetrievalError && tokenRetrievalError !== "" && actionSecret === "unknown" && <p style={{ color: "var(--red)" }}>Error retrieving your token: {tokenRetrievalError}</p>}

      { copiedMessage.show &&
        <div id="flashMessageDiv" style={{ top: copiedMessage.top, left: copiedMessage.left }}>
          <FlashMessage duration={2000}>
            <strong>Copied!</strong>
          </FlashMessage>
        </div>
      }
    </div>
  )
};

export default BotCommands;