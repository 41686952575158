import "./styles/Card.css";
import React from "react";

const Card = (props) => (
  <a href={props.href}>
    <div className="card">
      <div className="cardLabel">
        {props.children}
      </div>
    </div>
  </a>
)

export default Card;