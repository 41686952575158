import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import './styles/StreamAPIToken.css';
import { platformFromUser } from "../helpers/platformFromUser";
import { streamAPITokenServer } from "../config/react.env";

const StreamAPIToken = () => {
  const [isLoadingToken, setIsLoadingToken] = useState(false);
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);
  const [hideToken, setHideToken] = useState(true);
  const [copiedMessage, setCopiedMessage] = useState("Copied");
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);
  const [tokenRetrievalAttempted, setTokenRetrievalAttempted] = useState(false);

  const { user, getAccessTokenSilently } = useAuth0();

  const getToken = async () => {
    setTokenRetrievalAttempted(true);
    setIsLoadingToken(true);
    setToken(null);
    setError(null);

    try {
      const token = await getAccessTokenSilently();

      fetch(
        `${streamAPITokenServer}/api/rocket-league/stream-api/get-token`,
        {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(response => {
          if (response.status !== 200) {
            console.error(`Failed to retrieve token from server. Status code: ${response.status}. User: ${JSON.stringify(user)}`)
            setError("Failed to retrieve token from server.");
            return null;
          } else {
            return response.json();
          }
        })
        .catch(() => {
          console.error("Token server appears to be down.");
          setError("Failed to retrieve token from server. It appears the token server is down. DaftPenguin has been notified and will fix this eventually. Sorry for the inconvenience.");
        })
        .then(data => {
          if (data.token) {
            setToken(data.token);
          }
    
          setIsLoadingToken(false);
        })
        .catch(() => {
          console.error("Token server appears to be down.");
          setError("Failed to retrieve token from server. It appears the token server is down. DaftPenguin has been notified and will fix this eventually. Sorry for the inconvenience.");
        });
    } catch (error) {
      console.error(`Failed to retrieve token from server. Error message: ${error.message}. User: ${JSON.stringify(user)}`)
      setError("Failed to retrieve token from server.");
      setIsLoadingToken(false);
    }
  }

  const createToken = async () => {
    setTokenRetrievalAttempted(true);
    setIsLoadingToken(true);
    setError(null);

    try {
      const token = await getAccessTokenSilently();

      fetch(
        `${streamAPITokenServer}/api/rocket-league/stream-api/create-token`,
        {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(response => {
          if (response.status !== 200) {
            console.error(`Failed to create token on server. Status code: ${response.status}. User: ${JSON.stringify(user)}`)
            setError("Failed to create token on server.");
            return null;
          } else {
            return response.json();
          }
        })
        .then(data => {
          if (data && data.token) {
            setToken(data.token);
          }
    
          setIsLoadingToken(false);
        });
    } catch (error) {
      console.error(`Failed to create token on server. Error message: ${error.message}. User: ${JSON.stringify(user)}`)
      setError("Failed to create token on server.");
      setIsLoadingToken(false);
    }
  }

  const toggleHide = () => {
    setHideToken(!hideToken);
  }

  const copyToken = () => {
    var platform = platformFromUser(user);
    if (platform) {
      var data = {
        user: user.name,
        email: user.email,
        platform: platform.toLowerCase(),
        token: token,
      };
      navigator.clipboard.writeText(JSON.stringify(data, null, 4));
    }
    setCopiedMessage(platform ? "Copied" : "Failed to determine platform, failed to copy");
    setShowCopiedMessage(true);
    setTimeout(() => setShowCopiedMessage(false), 2000);
  }

  var platform = platformFromUser(user);

  useEffect(() => {
    if (!tokenRetrievalAttempted) {
      if (!platform) {
        fetch(`/api/error?err=Cannot parse platform from ${user.sub}`);
        setError("Failed to parse platform. Cannot create/retrieve token without a valid platform. This error has been logged and reported to the system administrator.");
      } else {
        getToken();
      }
    }
  });
  
  var showButtons = platform != null;
  var hasToken = !error && !isLoadingToken && token;
  var noTokenCreated = !isLoadingToken && !token;

  var tokenData = <p>Token: Loading...</p>;
  if (error != null) {
    tokenData = <p>Token: {error}</p>;
  } else if (!isLoadingToken) {
    if (token == null) {
      tokenData = <p>Token: No token</p>;
    } else if (hideToken) {
      tokenData = <p>Token: ***</p>;
    } else {
      if (platform) {
        var data = {
          user: user.name,
          email: user.email,
          platform: platform.toLowerCase(),
          token: token,
        };
        tokenData = <div style={{paddingBottom: "10px"}}><p>Token:</p><div className="textarea">{JSON.stringify(data, null, 4)}</div></div>;
      } else {
        tokenData = <p>Failed to parse platform, cannot proceed.</p>
      }
    }
  }

  return (
    <div>
      {tokenData}
      {showButtons && hasToken && <button onClick={toggleHide}>{hideToken ? "Show" : "Hide"}</button>}
      {showButtons && hasToken && <button onClick={copyToken}>Copy</button>}
      {showButtons && noTokenCreated && <button onClick={createToken}>Generate</button>}
      {showButtons && !noTokenCreated && !isLoadingToken && <button onClick={createToken}>Regenerate</button>}
      {showCopiedMessage && <span style={{ color: "var(--red)", paddingLeft: 20 }}>{copiedMessage}</span>}
    </div>
  );
}

export default StreamAPIToken;