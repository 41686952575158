import React, { Component } from 'react';
import axios from 'axios';
import './styles/TrainingPacksList.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import FlashMessage from 'react-flash-message';
import { Clipboard } from 'react-bootstrap-icons';
import Spinner from 'react-bootstrap/Spinner'

const host = "https://www.daftpenguin.com";

class TrainingPacksList extends Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(props.params);
    const offense = params.get("offense");
    const defense = params.get("defense");
    const description = params.get("description");
    const creator = params.get("creator");
    const selectedTags = params.get("tags") ? decodeURI(params.get("tags")).split(",") : null;

    this.state = {
      type: "packs",
      description: description ? description : "",
      creator: creator ? creator : "",
      selectedTags: selectedTags ? new Set(selectedTags) : new Set(),
      availableTags: selectedTags ? selectedTags : [],
      packs: {},
      copiedMessage: {
        show: false,
        top: 0,
        left: 0,
      },
      isSearching: false,
      hasSearched: false,
    }

    if (props.params) {
      this.isSearching = true;
      this.hasSearched = true;
      this.getPacks();
    }

    axios.post(`${host}/api/rocket-league/teamtraining/search`, { type: "tags" })
      .then(res => {
        if (res.status === 200) {
          this.setState({ availableTags: res.data });
        }
      });
  }

  getPacks = () => {
    const searchState = {
      type: this.state.type,
      offense: 0,
      defense: 0,
      description: this.state.description,
      creator: this.state.creator,
      tags: this.state.selectedTags == null ? null : Array.from(this.state.selectedTags),
    };

    console.log(searchState);

    axios.post(`${host}/api/rocket-league/teamtraining/search`, searchState)
      .then(res => {
        if (res.status === 200) {
          this.setState( { isSearching: false });
          this.setState({ packs: res.data
            .filter(pack => pack.offense + pack.defense === 1 && pack.code !== null && pack.code !== "")
            .reduce(function(map, pack) {
              map[pack.code] = pack;
              return map;
            }, {})
          });
          var params = [];
          if (searchState.offense > 0) params.push("offense=" + searchState.offense);
          if (searchState.defense > 0) params.push("defense=" + searchState.defense);
          if (searchState.description !== "") params.push("description=" + encodeURI(searchState.description));
          if (searchState.creator !== "") params.push("creator=" + encodeURI(searchState.creator));
          if (searchState.tags.length > 0) params.push("tags=" + encodeURI(searchState.tags.join(",")));
          if (params.length > 0) {
            var newURL = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + params.join("&");
            console.log(newURL);
            window.history.pushState({path:newURL}, '', newURL);
          }
        }
      });
  }

  handleChange = event => {
    this.setState({ [event.target.name] : event.target.value });
  }

  handleTagsChange = event => {
    const tag = event.target.name;
    const isChecked = event.target.checked;
    this.setState(prevState => {
      if (isChecked) {
        prevState.selectedTags.add(tag);
      } else {
        prevState.selectedTags.delete(tag);
      }
      return { selectedTags: prevState.selectedTags };
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    this.setState( { isSearching: true, hasSearched: true });
    this.getPacks();    
  }

  handleCopyCode = (event, code) => {
    event.preventDefault();
    navigator.clipboard.writeText(code);
    this.setState({ copiedMessage: {
      show: true,
      top: event.pageY,
      left: event.pageX,
    }});
    setTimeout(() => this.setState({ copiedMessage: { show: false } }), 2000);
  }

  render() {
    var trainingPacksTable = "";
    if (Object.entries(this.state.packs).length > 0) {
      trainingPacksTable = <table className="trainingPacks">
        <thead>
          <tr>
            <th>Code</th>
            <th>Description</th>
            <th>Creator</th>
            <th style={{minWidth: 200}}>Tags</th>
            <th>Drills</th>
            <th>Downloads</th>
            <th>YouTube Link</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(this.state.packs).map(function(entry) {
            const pack = entry[1];
            return (
              <tr key={pack.code}>
                <td>
                  <Clipboard className="clipboard" onClick={(event) => {this.handleCopyCode(event, pack.code)}} />
                  {pack.code}
                </td>
                <td>{pack.description}</td>
                <td>{pack.creator_name}</td>
                <td>{pack.tags == null ? "" : pack.tags.replaceAll(",", ", ")}</td>
                <td>{pack.num_drills}</td>
                <td>{pack.downloads}</td>
                <td>{pack.youtube_link == null ? "" : <a href={pack.youtube_link}>link</a>}</td>
                <td>{pack.creator_notes}</td>
              </tr>
            );
          }, this)}
        </tbody>
      </table>
    } else if (!this.state.isSearching && this.state.hasSearched) {
      trainingPacksTable = "0 training packs match your search query";
    }

    var spinner = "";
    if (this.state.isSearching) {
      spinner = <div style={{ display: "inline" }}>
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
    }

    return (
      <div>
        <div className="searchForm">
          <form onSubmit={this.handleSubmit}>
            <label>
              Description:
              <input type="text" name="description" onChange={this.handleChange} value={this.state.description} />
            </label>
            <br/>
            <label>
              Creator:
              <input type="text" name="creator" onChange={this.handleChange} value={this.state.creator} />
            </label>
            <fieldset className="tagCheckboxes">
              <p>Tags:</p>
              {this.state.availableTags.map(function(tag) {
                return (
                  <label htmlFor={tag} key={tag}>
                    <input type="checkbox" onChange={this.handleTagsChange} id={tag} name={tag} value={tag} checked={this.state.selectedTags.has(tag)} />
                    <span style={{ paddingLeft: "5px" }}>
                      {tag}
                    </span>
                  </label>
                )
              }, this)}
            </fieldset>
            <br/>
            <div style={{ paddingBottom: 30 }}>
              <button type="submit" style={{ float: "left", marginRight: 30 }}>Search</button>
              {spinner}
            </div>
          </form>
        </div>
        <br/>
        <div className="trainingPacks">
          {trainingPacksTable}
        </div>
        { this.state.copiedMessage.show &&
          <div id="flashMessageDiv" style={{ top: this.state.copiedMessage.top, left: this.state.copiedMessage.left }}>
            <FlashMessage duration={2000}>
              <strong>Copied!</strong>
            </FlashMessage>
          </div>
        }
      </div>
    )
  }
}

export default TrainingPacksList;