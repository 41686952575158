import React from "react";
import LoginCard from "../../components/LoginCard"
import { useAuth0 } from "@auth0/auth0-react";
import StreamAPIToken from "../../components/StreamAPIToken";
import { platformFromUser } from "../../helpers/platformFromUser";
import Card from "../../components/Card";
import BotCommands from "../../components/BotCommands";

const rootUrl = "/rocket-league/stream-api"; // I'm sure there's a much better way to do this

export const StreamAPI = ({ match: {params: {content, props}} }) => {
  var body = <External />;
  if (content === "setup") {
    body = <Setup />;
  } else if (content === "local") {
    body = <Local />;
  } else if (content === "commands") {
    body = <BotCommands botType={props} />;
  }

  return (
    <div>
      <h1>Stream API Plugin for BakkesMod</h1>
      <span style={{color:"red"}}>Sorry, creating an account for external bot support is no longer available.
        I have not been providing updates / support for the StreamAPI plugin for awhile now, and it's partly broken at this point.
      </span>
      {body}
    </div>
  );
}

const Setup = () => (
  <div>
    <h4>Plugin Setup</h4>
    <p>Choose your bot type (external setup path will also work with internal bots):</p>
    <p>Locally hosted bots run as applications on your computer, eg: Streamlabs Chatbot (not Cloudbot). This should also work with dual streaming setups where the bot is running on a different PC than your game.</p>
    <p>Externally hosted bots are usually configured in your browser and don't require you to run an application on your computer, eg: Nightbot.</p>
    <Card href={rootUrl}>Externally Hosted Bots</Card>
    <Card href={rootUrl + "/local"}>Locally Hosted Bots</Card>
    <hr />
    <Donation />
  </div>
)

const Local = () => (
  <div>
    <h4>Local Bot Setup</h4>
    <p style={{ color: "var(--red)" }}>This does NOT work on externally hosted bots. See instructions <a href={rootUrl}>here</a> for externally hosted bots.</p>
    <ol>
      <li>Open Rocket League with BakkesMod injected and the Stream API plugin installed and loaded.</li>
      <li>Navigate to Stream API plugin's settings (F2 menu -&gt; Plugins tab -&gt; Select Stream API plugin).</li>
      <li>Local bot support should be enabled by default, but if it isn't, enable it.</li>
      <li>Verify the local http server status says it's running.</li>
      <div style={{ paddingLeft: "20px", paddingTop: "10px" }}>
        <p><span style={{ fontWeight: "bolder" }}>No running?</span> It's possibel the default port is being used by another application. Change the port by consulting with the instructions in the plugin's settings.</p>
      </div>
      <li>Open <a href="http://127.0.0.1:10111/cmd?cmd=loadout">http://127.0.0.1:10111/cmd?cmd=loadout</a> in a browser running on the same PC as your game and verify you see your car's loadout information.</li>
      <div style={{ paddingLeft: "15px", paddingTop: "10px" }}>
        <p><span style={{ fontWeight: "bolder" }}>No data?</span> If you changed the port number, replace the 10111 in the URL with the port number you chose.</p>
        <p><span style={{ fontWeight: "bolder" }}>Dual PC Setup?</span> After confirming the data shows in a browser on the same PC as RL, try opening the same URL on another PC, but replace 127.0.0.1 with the local IP address of the PC that is running Rocket League. The loadout data should show. If no data is showing, it's likely your network either isn't configured as a private network, or you have a firewall on the PC running RL that is blocking the connection. I cannot offer specific advice on how to fix this, but <a href="/rocket-league/stream-api/">External Bot support</a> is an alternative solution if you fail to get it working.</p>
      </div>
      <li><a href="/rocket-league/stream-api/commands/local">Create bot commands</a></li>
    </ol>
    <p>If you have any issues related to installing or loading the plugin, please see my general <a href="/rocket-league/bakkesmod">BakkesMod help</a> page.</p>
    <p>If you have any other issues, <a href="/about">contact me</a> and I'll be happy to help when I am able to.</p>
    <hr />
    <Donation />
  </div>
)

const External = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <LoginCard />
        <Card href={rootUrl + "/setup"}>Setup Walkthrough</Card>
      </div>
      {!isAuthenticated && !isLoading && <LoginMessage />}
      {isAuthenticated && <Profile />}
      <hr />
      <ExternalSetup />
      <hr />
      <Donation />
      <hr />
      <FAQ />
    </div>
  );
}

const ExternalSetup = () => (
  <div>
    <h4>External Bot Setup Instructions</h4>
    <ol>
      <li>Login</li>
      <li>Click generate to create a token, then copy it</li>
      <li>Open Rocket League with BakkesMod injected and the Stream API plugin installed and loaded</li>
      <li>Navigate to Stream API plugin's settings (F2 menu -&gt; Plugins tab -&gt; Select Stream API plugin)</li>
      <li>Enable the external bot support section</li>
      <li>Click paste button to set the token and the plugin should start connecting to the website</li>
      <li>Verify the status says it's connected</li>
      <li><a href="/rocket-league/stream-api/commands/external">Create bot commands</a></li>
    </ol>
    <p>If you have any issues related to installing or loading the plugin, please see my general <a href="/rocket-league/bakkesmod">BakkesMod help</a> page.</p>
    <p>If you have any other issues, <a href="/about">contact me</a> and I'll be happy to help when I am able to.</p>
  </div>
)

const Donation = () => (
  <div>
    <h4>Donations</h4>
    <ul>
      <li><a href="https://streamlabs.com/daftpenguin1/tip">Streamlabs</a></li>
      <li>Bitcoin: 3J5Pov1cqdMFU5TSDw4Aqr8Lrpa1yAoQhC</li>
    </ul>
    <p>I did not make this plugin with the intention of making money, but it costs me money to provide external bot support in both server costs and subscriptions for services this feature depends on.</p>
    <p>Any contributions would be greatly appreciated and will further motivate me to continue developing the plugin and adding more features.</p>
  </div>
)

const FAQ = () => (
  <div>
    <h4>FAQ</h4>
    <h5>If the plugin exposes an API, why do I need this site?</h5>
    <p style={{paddingLeft:15}}>The API is hosted from within your game while the bot is hosted somewhere else on the internet. There's far too many things that can go wrong if you tried to expose the API externally for the chat bot to directly connect to it (opening ports in router, exposing IP address when creating commands via chat, IP address changing, etc). The plugin will instead create a long-lived connection with my website and push the data as it changes. The website will record the data and publish it externally through an API that bots can read from.</p>
    <h5>Why do I need to log in then?</h5>
    <p style={{paddingLeft:15}}>This is to verify that you are the owner of the streaming account to prevent possible abuse like someone installing the plugin on their machine and claiming to be you. The website will instead create a token associated with your streaming account that you will configure the plugin with. The plugin will pass this token to my server when creating a connection with it to verify your plugin data is legitiminate.</p>
    <p style={{paddingLeft:15}}>If you are uncomfortable with logging in, you can use create an alternate twitch account and login with it. The plugin data will be associated with the alternate account, but there is nothing restricting your bot's commands from retrieving data for it. Locally hosted bots are also another alternative. If you ever want your data removed, please <a href="/about">contact me</a>. However, I will need to verify your information before deleting your data (eg: whisper from twitch account or dm on discord with discord twitch connection set up).</p>
    <h5>Is this a phishing site?</h5>
    <p style={{paddingLeft:15}}>No. Obviously if it was I'd still say no, but there are ways you can guarantee that it isn't. Here's how:</p>
    <ol>
      <li>In another tab, go to your streaming platform.</li>
      <li>Make sure you're already logged in. If not, log in.</li>
      <li>Come back to this site, click login and choose your platform.</li>
      <ul>
        <li>You now shouldn't have to log into your platform and only need to authorize my site to access your account information.</li>
        <li>Make sure you do all this in one browser and if you're using a private browsing session, log into your streaming platform in the same private session.</li>
        <li>There might still be cases where for some reason you need to "verify" your login by entering your password. At the very least, click the lock icon next to the URL and verify it says the connection is secured for twitch.tv.</li>
      </ul>
    </ol>
    <p>Note: Login is handled by a separate service provided by <a href="https://www.auth0.com">auth0.com</a>, used by many large companies, in order carefully handle your account information (passwords never leave your streaming platform's website, but email address, username, and authentication token do).</p>
  </div>
)

const LoginMessage = () => (
  <div>
    <h4>Login via Streaming Platform</h4>
    <p>Logging into this website though your streaming platform will enable to you generate a token needed in order to publish data to the external API that can then be used by externally hosted bots.</p>
    <p>Please read FAQ below for more info.</p>
  </div>
)

const Profile = () => {
  const { user } = useAuth0();

  var platform = platformFromUser(user);
  if (!platform) platform = "Error";

  return (
    <div>
      <p>Username: {user.name}</p>
      <p>Email: {user.email}</p>
      <p>Platform: {platform}</p>
      <StreamAPIToken />
    </div>
  );
};