import React from 'react'

export const BakkesMod = () => (
  <div className="container">
    <h2>BakkesMod Help</h2>
    <h4>BakkesMod Installation</h4>
    <p>Installation issues are outside of the scope of this page, but nearly ever person I've directly helped consisted of just copying and pasting directions from the <a href="https://bakkesmod.fandom.com/wiki/Troubleshooting">BakkesMod Wiki: Troubleshooting</a> page. If that doesn't work, the next best option is posting to the <a href="reddit.com/r/bakkesmod">BakkesMod Sub-Reddit</a> where I'm a bit active.</p>
    <h4>Plugin Installation Troubleshooting</h4>
    
    <h5>DO NOT INSTALL PLUGINS BY DOWNLOADING THEIR ZIP</h5>
    <p style={{ paddingLeft: "15px" }}>Manually installing plugins prevents the plugin from being updated automatically and will also not enable the plugin to automatically load when BakkesMod injects.</p>

    <h5>"Install with BakkesMod" button seems to work but the plugin doesn't install</h5>
    <p style={{ paddingLeft: "15px" }}>Open Rocket League with BakkesMod injected, bring up the F6 console window, and run: plugin load pluginmanager. If you see an error in the console saying "LoadLibrary returned NULL...", <a href="https://bakkesmod.fandom.com/wiki/Troubleshooting#Cannot_open_the_plugin_manager_ingame_and_the_console_prints_.22LoadLibrary_returned_NULL.2C_..._Error_code:_126.22">follow these instructions</a>. If no error is shown, plugin manager was for some reason disabled from automatically loading when BakkesMod injects. Plugin manager is necessary for using the "Install with BakkesMod" button on the website and therefore I recommend enabling it in plugin manager's UI (check the checkbox next to it on the list).</p>

    <h5>"Install with BakkesMod" button doesn't do anything</h5>
    <p style={{ paddingLeft: "15px" }}>Instead, try installing the plugin by opening Rockey League with BakkesMod injected, open the F2 menu, navigate to the plugins tab, and open the plugin manager's UI. At the top right of the UI, you will see a textbox next to an "Install by ID" button. The plugin ID is the number in the plugin page's URL, eg: the ID for <a href="https://bakkesplugins.com/plugins/view/215">https://bakkesplugins.com/plugins/view/215</a> is 215. Enter the plugin ID in the textbox and click the "Install by ID" button. The plugin should now be installed.</p>
    
    <h5>Plugin's settings page is blank or the plugin's UI won't open</h5>
    <p style={{ paddingLeft: "15px" }}>This is usually because the plugin isn't loaded (did you manually install it?). Check the plugin manager to see if the plugin is enabled (checkbox is checked). If it isn't, check the checkbox. Otherwise, there might be an issue with the plugin and it failed to load. You can usually see this by checking the bakkesmod.log file in the BakkesMod folder.</p>
  </div>
)