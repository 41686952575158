import React from 'react'
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

import kekw from '../../assets/KEKW.jpg';

const GlobalStyle = createGlobalStyle`
  body {
    background: black;
  }
`;

const Styles = styled.div`
.NoMatch-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .NoMatch-logo {
    animation: NoMatch-logo-shake infinite 0.5s linear;
  }
}

.NoMatch-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-top: -100px;
}

@keyframes NoMatch-logo-shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}
`;

export const NoMatch = () => (
  <React.Fragment>
    <GlobalStyle />
    <Styles>
      <div className="container">
        <header className="NoMatch-header">
          <p>
            You a bish
          </p>
          <img src={kekw} className="NoMatch-logo" alt="kekw" />

          <div style={{ fontSize: 18, textAlign: "center", marginTop: 100, color: "#404040" }}>
            <p>This is a 404 page I usually meme people with. If I didn't meme you here, the content you're looking for doesn't exist.</p>
            <p>If you think this is a bug, <a href="/about">please let me know</a>. Thanks!</p>
            <p style={{ fontSize: 40 }}>:)</p>
          </div>
        </header>
      </div>
    </Styles>
  </React.Fragment>
)