import React from 'react'

export const YouTube = () => (
  <div className="container">
    <h2>YouTube Things</h2>
    <ul>
      <li>Subcount API: <a href="https://www.daftpenguin.com/api/youtube/sub-count?channel=UCVhgxLUjwJB1YqYO-CBbJ_g">https://www.daftpenguin.com/api/youtube/sub-count?channel=UCVhgxLUjwJB1YqYO-CBbJ_g</a></li>
      <div>
        Replace the channel parameter with the channel you wish to lookup the subscount for. This API only returns the abbreviated sub count that regular viewers see on your channel page.
      </div>
    </ul>
  </div>
)