import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter
} from "react-router-dom";

import { Home } from './Home';
import { RocketLeague } from './sections/RocketLeague/RocketLeague';
import { StreamAPI } from './sections/RocketLeague/StreamAPI';
import { BakkesMod } from './sections/RocketLeague/BakkesMod';
import { Valorant } from './sections/Valorant/Valorant';
import { ApexLegends } from './sections/ApexLegends/ApexLegends';
import { Twitch } from './sections/Twitch/Twitch';
import { About } from './sections/Info/About';
import { YouTube } from './sections/YouTube/YouTube';
import { NoMatch } from './sections/Info/NoMatch';
import { TriHouse } from './sections/TriHouse/TriHouse';

import { Layout } from './components/Layout';
import { NavigationBar } from './components/NavigationBar';

import './styles/App.css';

const NavigationBarWithRouter = withRouter(NavigationBar);

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Router>
          <NavigationBarWithRouter />
          <Layout>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/rocket-league/stream-api/:content?/:props?" component={StreamAPI} />
              <Route path="/rocket-league/bakkesmod" component={BakkesMod} />
              <Route path="/rocket-league/:content?" component={RocketLeague} />
              <Route path="/valorant" component={Valorant} />
              <Route path="/apex-legends" component={ApexLegends} />
              <Route path="/twitch" component={Twitch} />
              <Route path="/youtube" component={YouTube} />
              <Route path="/trihouse" component={TriHouse} />
              <Route path="/about" component={About} />
              <Route component={NoMatch} />
            </Switch>
          </Layout>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;