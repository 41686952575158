import React from 'react'

export const Valorant = () => (
  <div className="container">
    <h2>Valorant Things</h2>
    <ul>
      <li>Rank API for Twitch: <a href="https://www.daftpenguin.com/api/valorant/rank?name=daftpenguin&number=KEKW">https://www.daftpenguin.com/api/valorant/rank?name=daftpenguin&number=KEKW</a></li>
      <div>Replace name and number with your info (name#number as shown in your Valorant). This API scrapes the data from <a href="https://tracker.gg/valorant/">https://tracker.gg/valorant/</a> which requires you to sign in with your Riot ID first before your rank is shown. I did NOT receive permissions from them to scrape this data, so <span style={{ fontWeight: 'bold' }}>please only use as a command for looking up the streamer's rank</span> and not a generalized version for anyone to lookup their own ranks.</div>
    </ul>
  </div>
)